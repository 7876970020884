<template>
    <main class="layout">
        <slot />
    </main>
</template>

<script>
import Cookies from "js-cookie";

export default {
    mounted() {
        if (! localStorage.getItem('visitor_id') && Cookies.get('visitor_id')) {
            localStorage.setItem('visitor_id', Cookies.get('visitor_id'));
        }
    }
}
</script>

<style lang="scss" scoped>
.layout {
    @apply flex flex-col mx-auto max-w-[500px] min-h-[100vh] sm:px-[20px] pt-[16px] pb-[40px];
}
</style>
